<template>
  <v-navigation-drawer width="100%" style="height: 94vh; z-index: 5; position: relative;">
  <!-- <v-navigation-drawer width="100%" style="height: 94vh; z-index: 9; position: relative;"> -->
    <v-toolbar dense flat>
      <v-toolbar-title>{{ getIssuesIssue.workflow.name }} </v-toolbar-title>
      <v-btn style="position: absolute; right: 1px;" icon @click="onCloseClicked">
        <v-icon>mdi-close-circle</v-icon>
      </v-btn>
    </v-toolbar>
    <v-skeleton-loader :loading="getIssuesIsLoading" :transition="transition" height="400px"
      type="list-item-avatar-two-line">
      <v-card v-if="getIssuesIssue" flat class="mx-auto flex-column scroll">
        <v-card-text>
          <v-row class="mx-0 mb-4 flex-column">
            <div class="dates">
              <div>
                Hora inicio visita:
                <span v-if="getIssuesIssue">
                  {{ getIssuesIssue.createdAt | date }}
                </span>
              </div>
              <div>
                Hora fin visita:
                <span v-if="getIssuesIssue">
                <!-- <span v-if="getIssuesIssue && getIssuesChangelog.length > 0">
                  {{ getIssuesChangelog[getIssuesChangelog.length - 1].createdAt | date }} -->
                  {{ getIssuesIssue.updatedAt | date }}
                </span>
              </div>
            </div>
            <div class="mb-2 grey--text">Estado</div>
            <div>
              <v-chip dark :color="getIssuesIssue.issueStatus.color">{{
              getIssuesIssue.issueStatus.name
              }}</v-chip>
            </div>
          </v-row>
          <v-row class="mx-0 mb-4 flex-column">
            <div class="mb-2 dark-grey--text">Reportada</div>
            <div class="black--text">
              {{ getIssuesIssue.reporter.displayName }}
              <span v-if="getIssuesIssue" class="ml-2 font-weight-light">{{
              getIssuesIssue.createdAt | date
              }}</span>
            </div>
          </v-row>
          <v-row class="mx-0 mb-4 flex-column">
            <div class="mb-2 grey--text">Tipo</div>
            <div class="d-flex align-center">
              <v-avatar left>
                <v-img :src="icon"></v-img>
              </v-avatar>
              <div class="ml-4 subtitle-1 black--text">
                {{ getIssuesIssue.issueType.name }}
              </div>
            </div>
          </v-row>
          <v-row class="mx-0 mb-4 flex-column">
            <div class="mb-2 grey--text">Lugar</div>
            <div class="black--text">
              <!-- <router-link
              :to="{ name: 'places', params: { placeId: getIssuesIssue.place.id }}"
              target="_blank"
                >-->
              {{ getIssuesIssue.place.name }}
              <!-- <v-icon class="ml-2" size="16">mdi-open-in-new</v-icon> -->
              <!-- </router-link> -->
            </div>
          </v-row>
          <v-row class="mx-0 mb-4 flex-column" v-for="(field, index) in getIssuesIssue.fields" :key="index">

            <div class="mb-2 grey--text">{{ field.name }}</div>
            <div v-if="field.fieldType.slug === 'short_text'" class="black--text" style="max-width:310px">
              {{ field.value }}
            </div>
            <div v-if="field.fieldType.slug === 'yes_no'" class="black--text">
              <span v-if="field.value == true">Si</span>
              <span v-if="field.value == false">No</span>
            </div>
            <div v-if="field.fieldType.slug === 'number'" class="black--text">
              {{ field.value }}
            </div>
            <div v-if="field.fieldType.slug === 'dropdown'" class="black--text">
              {{ field.value }}
            </div>
            <div v-if="field.fieldType.slug === 'multiple_choice'" class="black--text">
              <ul>
                <li v-for="(value, index) in field.value" v-bind:key="index">
                  {{ value }}
                </li>
              </ul>
            </div>
            <div v-if="field.fieldType.slug === 'long_text'" class="black--text">
              {{ field.value }}
            </div>
            <div v-if="field.fieldType.slug === 'file_upload'" class="black--text">
              <a :href="field.value" target="_blank">
                Archivo
                <v-icon class="ml-2" size="16">mdi-open-in-new</v-icon>
              </a>
            </div>
            <div v-if="field.fieldType.slug === 'signature'">
              <p>Firma:</p>
            </div>
            <div v-if="field.fieldType.slug === 'photo_upload' || field.fieldType.slug === 'signature'"
              class="black--text">
              <v-hover>
                <template v-slot:default="{ hover }">
                  <v-card width="230px" height="200px">
                    <v-img :src="field.value" width="230px" height="200px" class="sign-img"></v-img>
                    <v-fade-transition>
                      <v-overlay v-if="hover" absolute>
                        <v-btn text icon x-large @click.stop="onMagnify(field.value)">
                          <v-icon>mdi-magnify-plus-outline</v-icon>
                        </v-btn>
                      </v-overlay>
                    </v-fade-transition>
                  </v-card>
                </template>
              </v-hover>
            </div>
            <v-hover v-slot:default="{ hover }">
              <v-card width="90%">
                <v-carousel height="200px" show-arrows-on-hover cycle hide-delimiter-background
                  v-if="field.fieldType.slug === 'multiple_photos'">
                  <v-carousel-item :src="photo" v-for="(photo, index) in field.value" :key="index">
                    <v-fade-transition>
                      <v-overlay v-if="hover" absolute>
                        <v-btn text icon x-large @click.stop="onMagnify(photo)">
                          <v-icon>mdi-magnify-plus-outline</v-icon>
                        </v-btn>
                      </v-overlay>
                    </v-fade-transition>
                  </v-carousel-item>
                </v-carousel>
              </v-card>
            </v-hover>
            <div v-if="field.fieldType.slug == 'date'" class="black--text" style="max-width:310px">
              {{ field.value | dateFormat }}
            </div>
          </v-row>
          <v-row class="mx-0 my-6 flex-column">
            <v-divider></v-divider>
          </v-row>
          <!-- <v-row class="mx-0 mb-4 flex-column">
              <div class="mb-4 subtitle-2">Registro de cambios</div>
              <div v-for="(change, index) in getIssuesChangelog" :key="index">
                <div class="mb-4">
                  <v-avatar color="grey" size="24" class="mr-3">
                    <span class="body-2 white--text title font-weight-regular">
                      {{getInitials(change.author.displayName)}}</span>
                  </v-avatar>
                  <span class="black--text mr-1">{{ change.author.displayName }} </span>
                  <span v-if="change"> {{ change.createdAt | dateFromNow }} </span>
                </div>
                <div
                  class="mb-4 ml-9"
                  v-for="(item, index) in change.items"
                  :key="index">
                    <div v-if="item.field">
                      <div class="mb-2 dark-grey--text">
                        {{ item.field.name }}
                      </div>
                    </div>

                    <div v-else>
                      <div class="mb-2 dark-grey--text">
                        {{ item.fieldType.name }}
                      </div>
                    </div>

                    <div class="black--text">
                      <span v-if="item.fromValue">
                        {{ item.fromValue }}
                        <v-icon class="ml-1 mr-1" color="grey" size="18"
                          >mdi-arrow-right</v-icon
                        >
                      </span>
                    <span v-if="item.fieldType.slug === 'yes_no'" class="black--text">
                      <span v-if="item.toValue == 'true'">Sí</span>
                      <span v-if="item.toValue == 'false'">No</span>
                    </span>

                    <div
                      v-if="item.fieldType.slug === 'photo_upload'"
                      class="black--text">
                      <v-hover>
                        <template v-slot:default="{ hover }">
                          <v-card width="230px" height="200px">
                            <v-img :src="item.toValue" width="230px" height="200px"></v-img>
                            <v-fade-transition>
                              <v-overlay v-if="hover" absolute>
                                <v-btn
                                  text
                                  icon
                                  x-large
                                  @click.stop="onMagnify(item.toValue)"
                                >
                                  <v-icon>mdi-magnify-plus-outline</v-icon>
                                </v-btn>
                              </v-overlay>
                            </v-fade-transition>
                          </v-card>
                        </template>
                      </v-hover>
                    </div>

                    <v-hover v-slot:default="{ hover }">
                        <v-card width="20%" style="margin-left: 0rem">
                          <v-carousel
                            height="200px"
                            show-arrows-on-hover
                            cycle
                            hide-delimiter-background
                            v-if="item.fieldType.slug === 'multiple_photos'">
                              <v-carousel-item :src="photo" v-for="(photo, index) in item.toValue" :key="index">
                                <v-fade-transition>
                                  <v-overlay v-if="hover" absolute>
                                    <v-btn
                                      text
                                      icon
                                      x-large
                                      @click.stop="onMagnify(photo)"
                                    >
                                      <v-icon>mdi-magnify-plus-outline</v-icon>
                                    </v-btn>
                                  </v-overlay>
                                </v-fade-transition>
                              </v-carousel-item>
                          </v-carousel>
                        </v-card>
                    </v-hover>
                    </div>
                    <span v-if="item.fieldType.slug === 'status'">{{ item.toValue }}</span>
                </div>
              </div>
            </v-row> -->
        </v-card-text>
      </v-card>
    </v-skeleton-loader>
    <v-dialog v-model="dialog" max-width="800">
      <v-img :src="img" class="sign-img-big"></v-img>
    </v-dialog>
  </v-navigation-drawer>
</template>
<script>
import AppConfig from '@/constants/app-config'
import { to } from '@/helpers'
import { dateFromNow } from '@/mixins'
import { ISSUES_GET_CHANGELOG } from '@/store/actions.type'
import { ISSUES_REMOVE_ISSUE } from '@/store/mutations.type'
import { mapGetters } from 'vuex'
const positiveMarker = require('@/assets/img/maps/positivo.svg')
const negativeMarker = require('@/assets/img/maps/negativo.svg')
const pendingMarker = require('@/assets/img/maps/pendiente.svg')

export default {
  name: 'IssueSidebar',
  props: {},
  data() {
    return {
      dialog: false,
      img: null,
      transition: 'fade-transition'
    }
  },
  computed: {
    ...mapGetters([
      'getIssuesIsLoading',
      'getIssuesIssue',
      'getIssuesChangelog'
    ]),
    icon() {
      const field = this.getIssuesIssue.fields.find(field => field.name === AppConfig.testResultFieldName)
      if (field === undefined) {
        return pendingMarker
      } else {
        return field.value ? positiveMarker : negativeMarker
      }
    }
  },
  components: {},
  mounted() {
    this.getChangelog()
  },
  methods: {
    onMagnify(value) {
      this.dialog = true
      this.img = value
    },
    back() {
      this.$router.push('/issues')
    },
    async getChangelog() {
      const [err] = await to(
        this.$store.dispatch(ISSUES_GET_CHANGELOG, this.getIssuesIssue.id)
      )
      if (err) {
        this.error = err
        this.isLoading = false
      }
      this.isLoading = false
    },
    getInitials(displayName) {
      if (!displayName) return ''
      return displayName.charAt(0)
    },
    onCloseClicked() {
      this.$store.commit(ISSUES_REMOVE_ISSUE)
    }
  },
  mixins: [dateFromNow],
  filters: {
    hour: function (date) {
      if (!date) {
        return ''
      }
      const tempDate = new Date(date)
      const minutes = tempDate.getMinutes() < 10
        ? '0' + tempDate.getMinutes()
        : tempDate.getMinutes()
      const hours = tempDate.getHours()

      return `${hours}:${minutes}`
    }
  }
}
</script>
<style>
.dates {
  margin-bottom: 5%;
}

.sign-img {
  background-color: aliceblue;
}

.sign-img-big {
  background-color: whitesmoke;
}
</style>
